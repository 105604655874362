import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simulador-video',
  templateUrl: './simulador-video.component.html',
  styleUrls: ['./simulador-video.component.scss']
})
export class SimuladorVideoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
