import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-colaboradores',
  templateUrl: './colaboradores.component.html',
  styleUrls: ['./colaboradores.component.scss']
})
export class ColaboradoresComponent implements OnInit {

  colaboradores:Array<Object>;

  constructor() { 
    this.colaboradores = [
      {
        logo: "assets/img/colaboradores/utj.jpg",
        text: "La Universidad Tecnológica de Jalisco (UTJ) es una alternativa de formación profesional que permite a los jóvenes de Jalisco y del occidente del país, incorporarse en el corto plazo al sector productivo, o bien continuar sus estudios a nivel profesional.",
        link: "https://utj.edu.mx/"
      },
      /*{
        logo: "assets/img/colaboradores/sedena.jpg",
        text: "Industria Militar Mexicana; Armas, Equipos, Vehículos del Ejermex. - Foro de discusión Extra-Oficial especializado en Fuerzas Armadas, Seguridad y Defensa.",
        link: "https://www.gob.mx/sedena/"
      },*/ 
      {
        logo: "assets/img/colaboradores/unaq.jpg",
        text: "La UNAQ, es una universidad pública del estado de Querétaro con más de diez años de antigüedad, cuya misión es la de formar profesionales e investigadores para el sector aeronáutico.",
        link: "https://www.unaq.edu.mx/"
      },
      {
        logo: "assets/img/colaboradores/utcv.jpg",
        text: "Reconocida como la mejor Universidad Tecnológica del país, contribuye a la formación de profesionales, a través de un modelo educativo de vanguardia.",
        link: "http://www.utcv.edu.mx/"
      },
      {
        logo: "assets/img/colaboradores/abb.jpg",
        text: "ABB es proveedor líder de robots industriales, sistemas de producción modular y servicios. El enfoque dirigido a las soluciones ayuda a los fabricantes a mejorar la productividad, la calidad del producto y la seguridad del trabajador. ABB ha instalado más de 400.000 robots en todo el mundo",
        link: "https://new.abb.com/products/robotics/es/"
      }
    ];
  }

  ngOnInit() {
  }

}
