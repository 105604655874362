import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nuestros-clientes',
  templateUrl: './nuestros-clientes.component.html',
  styleUrls: ['./nuestros-clientes.component.scss']
})
export class NuestrosClientesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
