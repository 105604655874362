import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.scss']
})
export class OurTeamComponent implements OnInit {
  team: Array<Object>;
  constructor() { 
    this.team =
    [
      {
        photo: "assets/img/team/luis-arroyo-ceo.png",
        name: "Luis Arroyo",
        job: "CEO & Founder IRS México"
      },
      {
        photo: "assets/img/team/default.png",
        name: "Lic. Marco González",
        job: "Dpto. Legal"
      },
      {
        photo: "assets/img/team/default.png",
        name: "T.B.H.",
        job: "Dpto. Administrativo"
      },
      {
        photo: "assets/img/team/default.png",
        name: "Gonzaga Arroyo",
        job: "Dpto. de coordincación"
      },
      {
        photo: "assets/img/team/default.png",
        name: "Lic. Andrés Padilla",
        job: "Dpto. Recursos humanos"
      },
      {
        photo: "assets/img/team/default.png",
        name: "Lic. Sergio Medina",
        job: "Dpto. de finanzas"
      },
      {
        photo: "assets/img/team/default.png",
        name: "Rafael Hernández",
        job: "Soporte de operaciones"
      },
      {
        photo: "assets/img/team/default.png",
        name: "Ing. Felipe Navarro",
        job: "División Automotriz"
      },
      {
        photo: "assets/img/team/default.png",
        name: "T.B.H.",
        job: "División Aeroespacial"
      },
      {
        photo: "assets/img/team/default.png",
        name: "T. B. H.",
        job: "División Industria Militar"
      },
      {
        photo: "assets/img/team/default.png",
        name: "Ing. Juan Rendón",
        job: "División Manufacturación"
      },
      {
        photo: "assets/img/team/default.png",
        name: "T. B. H.",
        job: "División Académica"
      }
    ];
  }

  ngOnInit() {
  }

}
