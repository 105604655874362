import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule} from '@angular/common/http';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

//Routes
import { AppRoutingModule } from './app-routing.module';

//Components
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { NuestrosClientesComponent } from './home/nuestros-clientes/nuestros-clientes.component';
import { HeroBannerComponent } from './home/hero-banner/hero-banner.component';
import { SimuladorVueloComponent } from './home/simulador-vuelo/simulador-vuelo.component';
import { SimuladorVideoComponent } from './home/simulador-video/simulador-video.component';
import { MensajeCeoComponent } from './home/mensaje-ceo/mensaje-ceo.component';
import { ContactoHomeComponent } from './home/contacto-home/contacto-home.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { HeaderComponent } from './nosotros/header/header.component';
import { FiveStarsComponent } from './nosotros/five-stars/five-stars.component';
import { OurTeamComponent } from './nosotros/our-team/our-team.component';
import { MisionComponent } from './nosotros/mision/mision.component';
import { PartnershipComponent } from './partnership/partnership.component';
import { DivisionesComponent } from './divisiones/divisiones.component';
import { ColaboradoresComponent } from './colaboradores/colaboradores.component';
import { ContactoComponent } from './contacto/contacto.component';
import { BlogComponent } from './blog/blog.component';
import { SocialbuttonsComponent } from './socialbuttons/socialbuttons.component';

//Services
import { ContactoService } from './services/contacto.service';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    NuestrosClientesComponent,
    HeroBannerComponent,
    SimuladorVueloComponent,
    SimuladorVideoComponent,
    MensajeCeoComponent,
    ContactoHomeComponent,
    NosotrosComponent,
    HeaderComponent,
    FiveStarsComponent,
    OurTeamComponent,
    MisionComponent,
    PartnershipComponent,
    DivisionesComponent,
    ColaboradoresComponent,
    ContactoComponent,
    BlogComponent,
    SocialbuttonsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [ContactoService],
  bootstrap: [AppComponent]
})
export class AppModule { }
