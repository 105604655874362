import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partnership',
  templateUrl: './partnership.component.html',
  styleUrls: ['./partnership.component.scss']
})
export class PartnershipComponent implements OnInit {

  partners:Array<Object>;

  constructor() { 
    this.partners = [
      {
        robot: "",
        logo: "assets/img/partnership/partnership-logo-joulin.png",
        text: "Diseño, Fabricación y de soluciones de Grippers de Vacío de agarre más amplias y más grandes.",
        link: "https://www.joulin.com/"
      },
      {
        robot: "assets/img/partnership/partnership-abb-robot.jpg",
        logo: "assets/img/partnership/partnership-logo-abb.png",
        text: "Líder de robots industriales, sistemas de producción modular y servicios.",
        link: "https://new.abb.com/products/robotics/es/"
      },
      {
        robot: "assets/img/partnership/partnership-sanbot-robot.jpg",
        logo: "assets/img/partnership/partnership-logo-sanbot.png",
        text: "Expertos en robótica e inteligencia artificial.",
        link: "http://en.sanbot.com/index"
      },
      {
        robot: "assets/img/partnership/partnership-gudel-guide-system.jpg",
        logo: "assets/img/partnership/partnership-logo-gudel.png",
        text: "Fabricantes desde hace más de 60 años de componentes mecánicos y módulos robotizados.",
        link: "https://www.gudel.com/products/linearaxis/tmf"
      },
      {
        robot: "assets/img/partnership/partnership-kuka-robot.png",
        logo: "assets/img/partnership/partnership-logo-kuka.png",
        text: "Fabricantes mundiales de robots industriales y sistemas de soluciones automatizadas de fabricación.",
        link: "https://www.kuka.com/"
      },
      {
        robot: "assets/img/partnership/partnership-gurego-.jpg",
        logo: "assets/img/partnership/partnership-logo-gurego.png",
        text: "Soluciones en autimatización industrial.",
        link: "http://www.gurego.com.mx/"
      },
      {
        robot: "assets/img/partnership/partnership-ati-accesorios.jpg",
        logo: "assets/img/partnership/partnership-logo-ati.png",
        text: "Accesorios de ingeniería robótica y herramentales para brazos robóticos",
        link: "https://www.ati-ia.com/"
      },
      {
        robot: "assets/img/partnership/partnership-csjbot-robot.jpg",
        logo: "assets/img/partnership/partnership-logo-csjbot.jpg",
        text: "Fabricantes de Robots Androides y de Servicio.",
        link: "https://en.csjbot.com/"
      },
      {
        robot: "assets/img/partnership/partnership-mir-robot-autonomo.jpg",
        logo: "assets/img/partnership/partnership-logo-mir.jpg",
        text: "Mobile Industrial Robots es un fabricante líder de robots móviles autónomos.",
        link: "https://www.mobile-industrial-robots.com/"
      }
    ];
  }

  ngOnInit() {
  }

}
