import { Component, OnInit } from '@angular/core';
import {ContactoService} from '../../services/contacto.service';
import {Contacto} from '../..//models/contacto';
import { NgForm, FormControl, Validators } from '@angular/forms';
import { FormGroup, FormBuilder } from  '@angular/forms';

@Component({
  selector: 'app-contacto-home',
  templateUrl: './contacto-home.component.html',
  styleUrls: ['./contacto-home.component.scss']
})
export class ContactoHomeComponent implements OnInit {
  urlSendEmail:string = 'http://industrialrobotics.com.mx/api/EnviarEmail.php';
  public contacto:Contacto;
  public contactoEnviado:boolean=false;

  contactForm: FormGroup;
  contactData: Contacto;
  
  constructor(private _contactoService: ContactoService, private formBuilder: FormBuilder) { 
    this.contacto = new Contacto();
    this.createContactForm();
  }

  createContactForm(){
    this.contactForm = this.formBuilder.group({
      fullName: new FormControl('', [
        Validators.required,
        Validators.minLength(3)
      ]),  
      email: new FormControl('', [
        Validators.required,
        Validators.pattern("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?")
      ]),
      phone: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]),
      message: new FormControl('', [Validators.required])
    });
    console.log(this.contactForm);
  }

  onSubmit() {
    this.contacto.name =this.contactForm.value.fullName;
    this.contacto.email = this.contactForm.value.email;
    this.contacto.phone = this.contactForm.value.phone;
    this.contacto.message = this.contactForm.value.message;
    let htmlMessage:string = "<p>Nombre: "+this.contacto.name+"</p>"+
                     "<p>Telefono: "+this.contacto.phone+"</p>"+
                     "<p>Email: "+this.contacto.email+"</p>"+
                     "<p>Mensaje: "+this.contacto.message+"</p>";
    this._contactoService.sendEmail(htmlMessage).subscribe(
      result => {
        //console.log(result);
      },
      error => {
          console.log(<any>error);
      }
    );
    this.contactForm.reset();
    this.contactoEnviado = true;
  }

  ngOnInit() {
  }

}
